<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Author': 'Autor',
      'Read more': 'Ler mais',
      'Posted in': 'Postado em:',
      'No information posted.': 'Não há informações postadas.',
    },
    es: {
      'Author': 'Autor',
      'Read more': 'Ler mais',
      'Posted in': 'Postado em:',
      'No information posted.': 'Não há informações postadas.',
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: false,
      blog: null
    };
  },
  methods: {
    getBlog() {
      api
        .get('blog')
        .then(response => {
          this.loading = false
          if (response.data.status=='success') {
            this.blog = response.data.list
          } else {
            this.empty = true
          }
        })
        .catch(error => {
          this.errored = error
        })
    }
  },
  mounted() {
    this.getBlog()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Blog</h4>
        </div>
      </div>
    </div>
    <div class="row" v-if="blog">
      <div class="col-lg-12" v-for="blogs in blog" :key="blogs.index">
        <div class="card overflow-hidden">
          <div class="position-relative">
            <div>
            <div>
              <div class="row">
                <img :src="blogs.image" alt="" class="rounded">
              </div>
            </div>
              <div class="row">
                <div class="col-12">
                  <div class="text-primary p-3">
                    <h5 class="text-primary text-dark text-uppercase">{{ blogs.title }}</h5>
                    <p class="text-dark">{{ t('Author') }} {{ blogs.author }},  <b style="color: #e8e8e8; font-weight: 200">{{ t('Posted in') }} {{ blogs.date | moment("D/MM/YYYY") }}</b></p>
                    <p class="text-dark">{{blogs.text}}</p>
                    <button type="button" class="btn btn-outline-dark">{{ t('Read more') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="col-12">
        <b-alert show variant="default">{{ t('No information posted.') }}</b-alert>
      </div>
    </div>
  </Layout>
</template>